<template>
    <div class="alarm">
        <div class="header">
            <div class="left">
                <p>{{deviceObj.name}}</p>
                <p>IMEI:{{deviceObj.imei}}</p>
            </div>
            <div class="right">
                以下是本设备的报警信息
            </div>
        </div>

        <div class="content">
            <div class="item" v-for="(item, index) in alarmArr" :key="index">
                <img v-if="item.type == 'fence'" src="../../assets/images/plot/icon_list_device_blue.png" alt="">
                <img v-if="item.type == 'breakPower'" src="../../assets/images/plot/icon_list_device_red.png" alt="">
                <img v-if="item.type == 'lowPower'" src="../../assets/images/plot/icon_list_device_orange.png" alt="">
                <img v-if="item.type == 'offLine'" src="../../assets/images/plot/icon_list_device_gray.png" alt="">
                <div class="alarm-type">
                    <p>{{item.name}}</p>
                    <p>{{item.time}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            deviceObj: {}, // 地块信息
            alarmArr: [
                {name: '进入围栏', time: '2021-03-30 23:45:19', type: 'fence'},
                {name: '离开围栏', time: '2021-03-30 23:45:19', type: 'fence'},
                {name: '断电告警', time: '2021-03-30 23:45:19', type: 'breakPower'},
                {name: '低电量告警', time: '2021-03-30 23:45:19', type: 'lowPower'},
                {name: '设备离线', time: '2021-03-30 23:45:19', type: 'offLine'},
                {name: '离开围栏', time: '2021-03-30 23:45:19', type: 'fence'},
            ]
        }
    },
    mounted() {
        this.deviceObj = this.$route.params;
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
    .alarm {
        font-size: 14px;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 62px;
            padding: 12px 20px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 4px;
            .left {
                p:nth-child(1) {
                    font-weight: 600;
                    color: #4F4F4F;
                }
                p:nth-child(2) {
                    margin-top: 4px;
                    font-size: 12px;
                    color: #438EFD;
                }
            }
            .right {
                font-size: 12px;
                color: #AFAFAF;
            }
        }

        .content {
            padding: 17px 15px;
            box-sizing: border-box;
            .item {
                display: flex;
                width: 100%;
                height: 62px;
                padding: 12px;
                margin-bottom: 12px;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 4px;
                img {
                    width: 38px;
                    height: 38px;
                    margin-right: 12px;
                }
                p:nth-child(1) {
                    font-weight: 600;
                    color: #4F4F4F;
                }
                p:nth-child(2) {
                    margin-top: 4px;
                    font-size: 12px;
                    color: #787878;
                }
            }
        }
    }
</style>
